<template>
  <div class="headermain-style">
    <div class="header-main">
      <h1>虚拟货币安全智能研判系统</h1>
      <h4>
        以链上交易溯源及匿名身份穿透为核心，打击涉虚拟货币新型犯罪的一站式查控平台
      </h4>
      <div class="searchinput">
        <div class="inputbox">
          <el-input
            v-model="addresslist.dealaddress"
            placeholder="搜索地址 / 哈希 / 调证用户ID"
            @input="inputchange"
            @keyup.enter.native="canvasanalysis"
          >
            <i
              slot="prefix"
              :class="isLoading ? 'el-icon-loading' : 'el-icon-search'"
            ></i>
          </el-input>
          <div class="btnbox">
            <i class="el-icon-error" v-if="showbtn" @click="clearablebtn"></i>
            <el-button
              class="analysisbtn"
              type="success"
              v-if="showbtn && !userdisable"
              @click="canvasanalysis"
              >画布分析</el-button
            >
            <el-button
              type="primary"
              class="detailsbtn"
              v-show="showbtn && analysisshow"
              @click="datadetails"
              >数据详情</el-button
            >
          </div>
        </div>
        <div v-if="morechain" id="moreselectbox">
          <multiple-chains
            :addresslength="addresslength"
            :addressarray="addressarray"
            :selectwidth="selectwidth"
            @handlejumpdetails="handlejumpdetails"
          ></multiple-chains>
        </div>
      </div>
      <div class="riskmonitoring" v-if="isloadingrisk">
        <div class="risktoptitle">
          <p>恶意地址风险监控</p>
          <span>最近24小时涉案地址转账信息</span>
        </div>
        <div class="riskcontent">
          <div
            class="riskbox"
            v-for="(item, index) in riskDatalist"
            :key="index"
          >
            <p>{{ item.type }}</p>
            <div>
              <span>关联地址数</span
              ><span>{{ item.associatedAddressNumber }}</span>
            </div>
            <div>
              <span>转入总交易</span><span>{{ item.totalQuantity }}</span>
            </div>
            <div>
              <span>转入总金额</span
              ><span>{{ item.totalAmount }} {{ item.currencySymbol }}</span>
            </div>
          </div>
        </div>
      </div>

      <el-skeleton v-else animated class="riskmonitoringloading">
        <template slot="template">
          <div class="risktoptitle">
            <p>恶意地址风险监控</p>
            <span>最近24小时涉案地址转账信息</span>
          </div>

          <div class="riskcontent">
            <div
              v-for="(item, index) in loadingArray"
              :key="index"
              class="riskbox"
              style="background: #0f1721"
            >
              <el-skeleton-item
                variant="p"
                style="background: #1c2634; width: 150px; height: 34px"
              />
              <div
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <el-skeleton-item
                  variant="text"
                  style="background: #1c2634; width: 95px; height: 22px"
                />
                <el-skeleton-item
                  variant="text"
                  style="
                    background: #1c2634;

                    width: 180px;
                    height: 22px;
                  "
                />
              </div>
              <div
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <el-skeleton-item
                  variant="text"
                  style="
                    background: #1c2634;

                    width: 95px;
                    height: 22px;
                  "
                />
                <el-skeleton-item
                  variant="text"
                  style="
                    background: #1c2634;

                    width: 180px;
                    height: 22px;
                  "
                />
              </div>
              <div
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <el-skeleton-item
                  variant="text"
                  style="
                    background: #1c2634;

                    width: 95px;
                    height: 22px;
                  "
                />
                <el-skeleton-item
                  variant="text"
                  style="background: #1c2634; width: 180px; height: 22px"
                />
              </div>
            </div>
          </div>
        </template>
      </el-skeleton>
    </div>
    <div class="headermap-style">
      <div class="map-style">
        <Map
          v-if="requireSuccess && nationalCases"
          :nationalCases="nationalCases"
        ></Map>
      </div>
      <div class="linechart-style">
        <LineChart
          class="linechart-left"
          v-if="requireSuccess && addressTransfer"
          :addressTransfer="addressTransfer"
        ></LineChart>
        <div class="linechart-right">
          <countpage
            v-if="requireSuccess && platformData"
            :platformData="platformData"
          ></countpage>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "@/views/Analysis/Map.vue";
import LineChart from "@/views/Analysis/LineChart.vue";
import {
  dataMultiSearch
  // vertexdetail
} from "@/aips/generalcanvas/generalcanvas";
import { newdataMultiSearch,newdatatype } from "@/aips/addressanalysis";
import {
  caseStatistics,
  platformStatistic,
  riskTrade,
  spiteTrendgram,
} from "@/aips/homepage";
import { getAddCanvas } from "@/aips/canvas";
import { Analysisvertex } from "@/aips/addressanalysis";
import multipleChains from "@/views/newtheme/newhome/multipleChains.vue";
import { riskData } from "@/aips/risk.js";
import Stomp from "stompjs";
import countpage from "@/views/newtheme/newhome/countpage.vue";
import SockJS from "sockjs-client";
import { CountUp } from "countup.js";
import { request } from "@/utils/request";
export default {
  components: { multipleChains, Map, LineChart, countpage },
  data() {
    return {
      isloadingrisk: false,
      requireSuccess: false,
      loadingArray: [1, 2, 3, 4, 5, 6],
      // 按钮的显示
      showbtn: false,
      analysisshow: true,
      morechain: false,
      addresslist: {
        chainvalue: null,
        dealaddress: null,
      },
      platformData: {},
      addressarray: [],
      addresslength: 0,
      selectwidth: 720,
      isLoading: false,
      riskDatalist: [],
      userdisable: false,
      stomp: null,
      baseURL: null,
      nationalCases: null,
      requireSuccess: false,
      addressTransfer: null,
      relatedCaseAmount: 0,
      relatedCaseQuantity: 0,
    };
  },
  watch: {
    platformData: {
      handler(newVal, oldVal) {
        this.platformData = newVal;
      },
      // 开启深度侦听
      deep: true,
      // 立即监听
      immediate: true,
    },
  },

  methods: {
    websoketfn() {
      let _this = this;
      // var stomp = null;
      var url = this.baseURL + "/ws"; //地址是后端给的
      console.log(this.baseURL);
      // var url = this.websoketurl + "/ws"; //地址是后端给的

      // var url = "https://api.tanlian.online/chain-api/ws"; //地址是后端给的
      var ws = new SockJS(url);
      _this.stomp = Stomp.over(ws);
      //连接
      _this.stomp.connect({}, function (frame) {
        //console.log(frame);

        //订阅，一般只有订阅的时候在返回
        // 风险交易预警
        _this.stomp.subscribe("/api/index/riskTrade", async function (res) {
          const data1 = await JSON.parse(res.body);
          _this.riskData = data1.data; //这里取自己需要的值就行
          //console.log("风险");
          //console.log(_this.riskData);
        });

        // 平台数据统计
        _this.stomp.subscribe(
          "/api/index/platformStatistic",
          async function (res) {
            const data2 = await JSON.parse(res.body);
            _this.platformData = data2.data;
            // console.log(data2.data);
          }
        );

        // 恶意地址转账趋势图
        _this.stomp.subscribe(
          "/api/index/spiteTrendgram",
          async function (res) {
            const data3 = await JSON.parse(res.body);
            _this.addressTransfer = data3.data;
          }
        );

        // // 全国案件判例
        _this.stomp.subscribe(
          "/api/index/caseStatistics",
          async function (res) {
            const data4 = await JSON.parse(res.body);
            //console.log("全国案例数据");
            // //console.log(data4.data);
            _this.nationalCases = data4.data;
            //console.log(_this.nationalCases);
          }
        );
      });
    },
    // echarsdata(){
    //   //风险交易预警
    //   riskTrade().then(res=>{
    //     if(res.code==200){
    //       this.riskData = res.data;
    //     }
    //   })
    //   //恶意地址转账趋势图
    //   spiteTrendgram().then(res=>{
    //     if(res.code==200){
    //       this.addressTransfer = res.data;
    //     }
    //   })
    //   //平台数据统计
    //   platformStatistic().then(res=>{
    //     if(res.code==200){
    //       this.platformData = res.data;
    //     }
    //   })
    //     //全国案件判例
    //     caseStatistics().then(res=>{
    //     if(res.code==200){
    //       this.nationalCases = res.data;
    //     }
    //   })
    // },
    clearablebtn() {
      this.addresslist.dealaddress = "";
      this.showbtn = false;
    },
    // 画布分析
    canvasanalysis() {
      if (this.userdisable) {
        return;
      }
      // console.log("打印一下我是什么");
      // console.log(this.addresslist);
   
      let typeRequest ={
    input: this.addresslist.dealaddress
   }
   newdatatype(typeRequest).then(resquest=>{
     if(resquest.code==200){
      // console.log(resquest);
      if(resquest.data.type===2){
        this.isLoading = true;
        let multiSearchRequest ={
          input: this.addresslist.dealaddress,
          simpleCheck: 1,
        }
        dataMultiSearch(multiSearchRequest).then(res=>{
          this.isLoading = false;
          if(res.code==200){
            console.log(res);
            let graph = {
                  all: false,
                  edgeSize: 0,
                  edges: [],
                  empty: false,
                  vertexSize: 0,
                  vertices: [],
                };
                let data = {
                graph: JSON.stringify(graph),
                name: new Date(),
                description: null,
            
                typeCode:res.data.typeCode,
                // canvasConfigId:res.data.canvasConfigId, 
              }; 
              getAddCanvas(data).then(resdata=>{
                if(resdata.code==200){
                  // console.log(resdata);
                  this.$router.push({
          path: "/canvaslist/newcanvas",
          query: { id: resdata.data.id, type: resdata.data.type,address:this.addresslist.dealaddress},
        });
                }
              })
          }
        })
      }else{
        this.isLoading = true;
     const data = {
       input: this.addresslist.dealaddress,
       simpleCheck: 1,
     };
     if (this.analysisshow) {
       newdataMultiSearch(data).then((res) => {
         console.log(res);
         this.isLoading = false;
         if (res.code == 200) {
           if (res.data.resultType == 1 || res.data.resultType == 2) {
             console.log("打印我");
             console.log(this.addresslist);
             let caseAnalysisone = this.$router.resolve({
               path: "/canvaslist/newcanvas",
               query: {
                 temporarilycanvas: true,
                 addresslist: JSON.stringify(this.addresslist),
               },
             });
             window.open(caseAnalysisone.href, "_blank");
           } else if (res.data.resultType == 3) {
             let caseAnalysisone = this.$router.resolve({
               path: "/canvaslist/newcanvas",
               query: {
                 temporarilycanvas: true,
                 addresslist: JSON.stringify(this.addresslist),
               },
             });
             window.open(caseAnalysisone.href, "_blank");
           }
         } else {
         }
       });
     } else {
       this.isLoading = false;
       let caseAnalysisone = this.$router.resolve({
         path: "/canvaslist/newcanvas",
         query: {
           temporarilycanvas: true,
           addresslist: JSON.stringify(this.addresslist),
         },
       });
       window.open(caseAnalysisone.href, "_blank");
     }
      }
    }
   })

    },

    // 数据详情
    datadetails() {
      // this.morechain = true;
      this.isLoading = true;
      let typeRequest ={
    input: this.addresslist.dealaddress
   }
   newdatatype(typeRequest).then(resquest=>{
    this.isLoading = false;

    if(resquest.code==200){
      if(resquest.data.type===2){
        this.$message({
              message: "该地址暂不支持",
              type: "warning",
              duration: 1000,
            });
      }else{
        const data = {
        input: this.addresslist.dealaddress,
        simpleCheck: 0,
      };
      newdataMultiSearch(data).then((res) => {
        // console.log("我是res");
        // console.log(res);
        this.isLoading = false;
        if (res.data.resultType == 1) {
          // 地址
          if (res.data.resultDatas.length > 1) {
            this.addressarray = res.data.resultDatas;
            this.morechain = true;
            this.addresslength = res.data.resultDatas.length;
          } else {
            const data = res.data.resultDatas[0];
            this.jumpdetails(data);
          }
        } else if (res.data.resultType == 2) {
          // 调证
          this.$router.push({
            path: `/home/userdetailspage/${this.addresslist.dealaddress}`,
            query: { searchcontent: this.addresslist.dealaddress },
          });
        } else if (res.data.resultType == 3) {
          //哈希
          const data = JSON.stringify(res.data.resultDatas[0]);
          this.$router.push({
            path: `/home/transactiondetails/${res.data.resultDatas[0].hash}`,
            query: { hashdata: data },
          });
        }
      });
      }
    }
   })
   
    },
    // input值发生改变
    inputchange(v) {
      if (v !== "") {
        this.showbtn = true;
 
      }
      const hasSpace = /\s/.test(v);
      // console.log(hasSpace);
      if (hasSpace) {
        this.analysisshow = false;
      } else {
        this.analysisshow = true;
      }
    },
    jumpdetails(v) {
      // let data = {
      //   dealaddress: this.addresslist.dealaddress,
      //   chainvalue: v.blockchain.code
      //     ? v.blockchain.code
      //     : this.addresslist.chainvalue,
      // };
      let vertexRequest = {
        address: v.address,
        blockchain: v.blockchain.code,
      };
      Analysisvertex(vertexRequest).then((res) => {
        console.log(res);
        if (res.code == 200) {
          if (res.data.vertices.length > 0) {
            // if (data.chainvalue == "" || data.chainvalue == null) {
            //   res.data.vertices[0].tags.forEach((element) => {
            //     element.properties.forEach((item) => {
            //       if (item.name == "blockchain") {
            //         data.chainvalue = item.value.toUpperCase();
            //       }
            //     });
            //   });
            // }
            // data.detailList = res.data.vertices[0];
            this.$emit("goanalysis", v);
          } else {
            this.$message({
              message: "没有查出该地址",
              type: "warning",
              duration: 1000,
            });
          }
          // JSON.stringify(res.data.vertices[0])
        }
      });
    },
    handlejumpdetails(v) {
      this.jumpdetails(v);
    },
  },
  mounted() {
    let userroles = JSON.parse(localStorage.roles);
    console.log(userroles);
    userroles.sort(function (a, b) {
      return b.weight - a.weight;
    });

    if (userroles[0].label == "initial_role") {
      this.userdisable = true;
    } else {
      this.userdisable = false;
    }
    document.addEventListener("click", (e) => {
      const moreselectbox = document.getElementById("moreselectbox");
      if (moreselectbox) {
        if (!moreselectbox.contains(e.target)) {
          this.morechain = false;
        }
      }
    });
    riskData().then((res) => {
      console.log(res);
      if (res.code == 200) {
        const indexToRemove = res.data.findIndex((item) => {
          return item.type === "诈骗地址";
        });

        if (indexToRemove !== -1) {
          res.data.splice(indexToRemove, 1);
        }
        res.data.forEach((item) => {
          item.totalAmount = item.totalAmount.toLocaleString();
          item.associatedAddressNumber =
            item.associatedAddressNumber.toLocaleString();
          item.totalQuantity = item.totalQuantity.toLocaleString();
        });
        this.riskDatalist = res.data;
        setTimeout(() => {
          this.isloadingrisk = true;
        }, 500);
      }
    });
    setTimeout(() => {
      console.log(this.platformData);
      this.relatedCaseAmount = this.platformData.relatedCaseAmount;
      this.relatedCaseQuantity = this.platformData.relatedCaseQuantity;
    }, 200);
    const options = {
      // 小数点位
      decimalPlaces: 0,
      // 持续时间
      duration: 2,
    };
    new CountUp(
      this.$refs.numberPrice,
      this.relatedCaseAmount,
      options
    ).start();
    new CountUp(
      this.$refs.assistanceEvents,
      this.relatedCaseQuantity,
      options
    ).start();
    // setTimeout(() => {
    //   console.log(this.platformData);
    //   this.relatedCaseAmount = this.platformData.relatedCaseAmount;
    // this.relatedCaseQuantity = this.platformData.relatedCaseQuantity;
    // }, 500);
  },
  created() {
    // setTimeout(() => {
    //   console.log(global.getConfigdata);
    //   global.getConfigdata.forEach((resitem) => {
    //     if (resitem.action == "LOGIN") {
    //       this.loginresource = resitem.configs;
    //     }
    //   });
    //   this.loginresource.forEach((item) => {
    //     if (item.configKey == "websocket") {
    //       this.websoketurl = item.configValue;
    //     }
    //   });
    //   // this.websoketurl = this.loginresource[3].configValue;
    //   console.log(this.websoketurl);
    // }, 300);

    // if (navigator.userAgent.indexOf("Mac OS X") !== -1) {
    //   this.ismac = true;
    // } else {
    //   this.ismac = false;
    // }
    this.baseURL = request.defaults.baseURL;
    this.websoketfn();
    // this.echarsdata()
    console.log(this.$route.path);
    // if (this.$route.path == "/home") {
    //   // this.ishome = true;
    // } else {
    //   // this.ishome = false;
    // }
    //console.log(this.$route.path);

    setTimeout(() => {
      this.requireSuccess = true;
    }, 1000);
  },
  beforeDestroy() {
    this.nationalCases = null;
    // this.requireSuccess=false
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/newhomepage/homemain.less";
</style>
